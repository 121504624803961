import { Video } from "../components/Videos"

export enum VideoCategory {
  General = "Información general",
  Paseos = "Paseos en Malta",
  Costos = "Costos",
  Testimonios = "Testimonios"
}

export const videos: Video[] = [
  {
    title: "Alojamiento en Malta",
    videoId: "Q_BRNtVLar0",
    description: "Elegir tu alojamiento en Malta, puede hacer la diferencia en toda tu experiencia de aprendizaje. No te pierdas esta información y recuerda contactarnos para asesorar tu viaje de estudios de inglés a Malta.",
    category: VideoCategory.General,
  },{
    title: "Presupuesto para estudiar en Malta 2023",
    videoId: "_6D7aYp5ZFM",
    description: "En este video te contamos todos los detalles que tienes que tener presente a la hora de planear tu viaje de estudios a Malta.",
    category: VideoCategory.Costos,
  },{
    title: "Mi experiencia en Malta pronto continuará!",
    videoId: "7twV_5BBO-w",
    description: "Valentina estuvo disfrutando su experiencia de aprendizaje en Malta, justo antes de que la pandemia  explotara. Al momento de ocurrir, decidió suspender su curso y regresar a su país Colombia. Hoy te compartimos la primera parte de su experiencia que pronto tendrá una parte 2, que seguramente también te compartiremos en su momento!",
    category: VideoCategory.Testimonios,
  },
  {
    title: "Estudiar inglés en Malta 2021",
    description: "Muchas cosas han cambiado este ańo y en este video, te queremos explicar todos lo que necesitas saber para que tengas una experiencia de estudios exitosa.",
    videoId: "p6ZcFTsKu0E",
    category: VideoCategory.General,
  }, {
    title: "Costo de vida en Malta 2021",
    description: "En este video te contamos todo loo que necesitas saber respecto a lo que será el costo de vida en Malta para los estudiantes, en este 2021.",
    videoId: "WbqbdNM6W3E",
    category: VideoCategory.Costos,
  }, {
    title: "Cómo calcular mi presupuesto de viaje?",
    description: "Para estudiar inglés en Malta debes planear muy bien tu presupuesto, pues ello te garantiza un viaje tranquilo. En este video te explicamos como hacerlo!",
    videoId: "iSMd_8E2SaI",
    category: VideoCategory.Costos,
  }, {
    title: "Desde Malta una Mexicana nos cuenta su experiencia!",
    description: "En este vídeo Diana nos cuenta sobre su maravillosa experiencia en Malta",
    videoId: "w6n8ip6hc7Q",
    category: VideoCategory.Testimonios,
  },
  {
    title: "Permiso de trabajo para estudiantes",
    description: "En este video te explica Ana en que consiste el permiso de trabajo para estudiantes provenientes de países diferentes a los pertenecientes a la Unión Europea. Mis publicaciones van enfocadas exclusivamente a estudiantes, para tramites de visa de trabajo o migración te aconsejo asesorarte de un experto en esa materia!",
    videoId: "Ble5zzzozAg",
    category: VideoCategory.General,
  }, {
    title: "Cómo aplicar a tu visa de estudiante en Malta",
    description: "En este video te dejamos la información acerca del proceso de visa de estudiante que debes realizar una vez llegas a la isla",
    videoId: "uGxfbe9vHGQ",
    category: VideoCategory.General,
  }, {
    title: "Inglés en Malta La experiencia de Janneth Medina",
    description: "En este video les compartamos la experiencia de Janneth, una experiencia que realmente renovó su energía para perseguir todos esos sueños que a veces dejamos a un lado. Una gran experiencia, llena de inspiración!!!",
    videoId: "teID5oRjZ7s",
    category: VideoCategory.Testimonios,
    thumbnailUrl: "https://i.ytimg.com/vi/teID5oRjZ7s/default.jpg",
    uploadDate: "2018-12-21T23:14:08.000Z",
    duration: "PT14M10S",
    embedUrl: "https://www.youtube.com/embed/teID5oRjZ7s",
    interactionCount: 2964,
  }, {
    title: "Inglés en Malta- La experiencia de Claudio de Chile",
    description: "Claudio nos cuenta como ha sido la experiencia de estudiar inglés en Malta, y como con un propósito claro y sobretodo buena actitud, logró aprender y disfrutar de una experiencia inolvidable",
    videoId: "h78kge1jttw",
    category: VideoCategory.Testimonios,
  },
  {
    title: "Cómo pasar migración en aeropuertos de Europa",
    description: "En este video les contamos todo lo que necesitan saber para tener un proceso migratorio en los aeropuertos exitoso.",
    videoId: "anTX4GkmzQ8",
    category: VideoCategory.General,
  }, {
    title: "Proceso migratorio a Malta (Parte 1: Requisitos generales)",
    description: "En este video les dejamos los requisitos generales que deben tener en cuenta quienes desean viajar a estudiar inglés a la isla, en materia migratoria",
    videoId: "CbA2ujv1dtQ",
    category: VideoCategory.General,
  },
  {
    title: "Consejos para la preparación de tu viaje",
    description: "En este video te damos algunos consejos para la preparación de tu viaje de estudios; pues estamos convencida que estudiar inglés en Malta requiere de una buena preparación para que tengas de igual forma una excelente experiencia.",
    videoId: "cpwNWSmSyv0",
    category: VideoCategory.General,
  }, {
    title: "Clima en Malta Qué hacer y qué ropa llevar",
    description: "El clima en Malta. Seguramente algunos de ustedes se han preguntado sí en Malta hace calor todo el año y que ropa traer cuando vienen. Aquí un video donde habla Ana del clima en Malta.",
    videoId: "WjjxIkJA8Jo",
    category: VideoCategory.General,
  }, {
    title: "Un viaje a Venecia Italia con estudiantes de Desde Malta",
    description: "Un viaje a Venecia Italia con estudiantes de Desde Malta",
    videoId: "RiCG_0zDvlY",
    category: VideoCategory.Paseos,
  }, {
    title: "Cosas para hacer en Malta. Paseo a Mdina y Rabat	",
    description: "Este video hace parte de la serie de videos acerca de cosas para hacer en Malta, que tienen como objetivo darles algunas ideas para que aprovechen al máximo su tiempo en la isla mientras están estudiando inglés.",
    videoId: "zEQyBTCKEUs",
    category: VideoCategory.Paseos,
  },
  {
    title: "Permiso de trabajo para estudiantes",
    description: "Un panorama completo de lo que es el permiso de trabajo para estudiantes en Malta, documentos que necesitas para tramitarlo, condiciones, las posibilidades de encontrar un trabajo en la isla y mucho más!",
    videoId: "xpl5ybgGA3s",
    category: VideoCategory.General,
  },
  {
    title: "Cosas que no pueden faltar en tu equipaje de mano",
    description: "En este video les voy a ayudar a preparar su equipaje de mano para que viajen a Malta con todo listo para iniciar con una experiencia única de estudiar inglés en el exterior.",
    videoId: "uj-97bo35Y8",
    category: VideoCategory.General,
  },
  {
    title: "Estudiando inglés en Malta durante el covid-19",
    description: "Llegar a estudiar inglés a Malta y encontrarte con el Covid-19. Como ha sido la experiencia de Senibaldo durante este tiempo, su aprendizaje y sus recomendaciones!",
    videoId: "pr45uS7x2GY",
    category: VideoCategory.Testimonios,
  },

  {
    title: "De Nueva York a Malta",
    description: "El destino no garantiza una buena experiencia; la clave está en la planeación. Daniel nos comparte su experiencia con el viaje para estudiar inglés.",
    videoId: "alhFj1tp8Go",
    category: VideoCategory.Testimonios,
    thumbnailUrl: "https://i.ytimg.com/vi/alhFj1tp8Go/default.jpg",
    uploadDate: "2017-06-20T11:01:20Z",
    duration: "PT3M35S",
    embedUrl: "https://www.youtube.com/embed/alhFj1tp8Go",
    interactionCount: 1439,
  }, {
    title: "Experiencia de Zaira con Desde Malta",
    description: "Zaira Mayorga, nos comparte su experiencia de planeación de viaje con Desde Malta",
    videoId: "KBhc07Tc1dQ",
    category: VideoCategory.Testimonios,
  }, {
    title: "Gastos que pueden evaporar tu presupuesto como estudiante de inglés en Malta",
    description: "En este video les cuentamos algunas cosas que a veces pasamos por alto y hacen que nuestro presupuesto se evapore sin darnos cuenta!",
    videoId: "gFdPjggFLnc",
    category: VideoCategory.Costos,
  }, {
    title: "Paseo a Gozo",
    description: "Este video hace parte de la serie de videos acerca de cosas para hacer en Malta, que tienen como objetivo darles algunas ideas para que aprovechen al máximo su tiempo en la isla mientras están estudiando inglés.",
    videoId: "0ZsZ6T3cTt0",
    category: VideoCategory.Paseos,
  }, {
    title: "Mónica habla sobre estudiar inglés en Malta",
    description: "En este video les compartamos la experiencia de Mónica con quien superamos todos los obstáculos para lograr que finalmente pudiera viajar a cumplir con su objetivo de mejorar su inglés como un paso hacía sus objetivos profesionales.",
    videoId: "mBFfRCCxsNo",
    category: VideoCategory.Testimonios,
  }, {
    title: "Qué esperar de un curso de inglés en Malta hasta por 3 meses",
    description: "En este video te explica Ana que esperar de un curso de inglés en Malta hasta por 3 meses",
    videoId: "jCSKoIp_7gA",
    category: VideoCategory.General,
  }, {
    title: "Qué esperar de un curso de inglés en Malta por entre 3 y 6 meses",
    description: "En este video te explica Ana que esperar de un curso de inglés en Malta por entre 3 y 6 meses.",
    videoId: "oxyqtBqHG3k",
    category: VideoCategory.General,
  }, {
    title: "Testimonio Andrea Romero",
    description: "Andrea Romero, nos comparte su experiencia de planeación de viaje con Desde Malta",
    videoId: "zSHhRvWIOgE",
    category: VideoCategory.Testimonios,
  }, {
    title: "Testimonio Paula Montes",
    description: "Paula nos comparte su experiencia de planeación de viaje con Desde Malta.",
    videoId: "P1_Jjh1M23A",
    category: VideoCategory.Testimonios,
  }, {
    title: "Tips para elegir Alojamiento",
    description: "En este video encuentras información general acerca de las opciones de alojamiento que puedes encontrar en Malta. Sin embargo, cada opción puede variar según la escuela que elijas.",
    videoId: "ZhJLiMki2kc",
    category: VideoCategory.General,
  }, {
    title: "Testimonio Lina",
    description: "Lina Palacio, comparte su experiencia de planeación de viaje con Desde Malta",
    videoId: "PPyW8my9PX0",
    category: VideoCategory.Testimonios,
  }, {
    title: "La experiencia de internship de Diana",
    description: "Acompañamos a Diana Barragan en toda su proceso de planeación de internship en Malta y compartimos con ustedes este balance de su experiencia",
    videoId: "8HVMghXfgnM",
    category: VideoCategory.Testimonios,
  }, {
    title: "Viajar por Europa mientras estudio inglés en Malta",
    description: "Malta como destino para estudiar inglés te ofrece una oportunidad única de visitar diferentes países de Europa en tus vacaciones o en viajes cortos de fin de semana. En este video te damos algunos tips y recomendaciones para que esta parte de tu experiencia de estudios en Malta, te deje",
    videoId: "RdNlNMZDrdw",
    category: VideoCategory.General,
  }, {
    title: "¿Cuánto cuesta estudiar inglés en Malta?",
    description: "La intención de este video es acercarlos un poco más a los precios de los programas de inglés, para que de esta manera puedan empezar a proyectar su viaje, metas de ahorro y presupuesto.",
    videoId: "p2bj3TBfmhg",
    category: VideoCategory.Costos,
  }, {
    title: "¿Qué programa de inglés elegir?",
    description: "En este video te explica Ana que programa de inglés elegir según tu nivel y objetivos.",
    videoId: "Z2ZBDrVf_uo",
    category: VideoCategory.General,
  }]