import * as React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import css from "classnames";

// @ts-ignore
import styles from "./styles.module.scss"

export enum ImageType {
  avatar = "AVATAR",
  squared = "SQUARED",
  round = "ROUND"
}

export interface PreviewItemProps {
  link: string;
  imageUrl: any;
  text: any;
  title: string;
  imageType?: ImageType;
  imageAlt?: string;
  related?: string;
  categoryTag?: string;
}

const PreviewItem = (props: PreviewItemProps) => {
  const {
    link,
    imageUrl,
    title,
    text,
    imageType = ImageType.squared,
    imageAlt = "icon link image",
  } = props
  const getImageClass = (): any => {
    if (imageType === ImageType.squared) {
      return styles.squared
    }
    if (imageType === ImageType.round) {
      return styles.round
    }
    if (imageType === ImageType.avatar) {
      return styles.avatar
    }
    return styles.squared
  }
  return (
    <div className={styles.linkComponent}>
      {props.related ?
        <div className={styles.related}>{props.related}</div>
        :
        <></>
      }
      <Link to={link} style={{ width: "100%" }}>
        {imageUrl && imageUrl.src &&
        <div className={styles.imageContainer}><Img className={css(getImageClass(), styles.grow)} fluid={imageUrl} alt={imageAlt || "post-preview"}/></div>}
        <div className={styles.tag}>{props.categoryTag === "opinion" ? "lifestyle" : props.categoryTag}</div>
        <div>
          <div className={styles.linkTitle}>{title}</div>
          <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }}/>
        </div>
      </Link>

    </div>
  )
}

export default PreviewItem