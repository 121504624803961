import React from "react"

const styles = require("./styles.module.scss")

export const TestimonialCard = ({text, name, location}) => {
  return (
    <div className={styles.testimonialCard}>
      <img src={require("../../images/quote.svg")} alt={"quote"}/>
      <div className={styles.text}>{text}</div>
      <div>
        <div className={styles.name}>{name}</div>
        <div className={styles.location}>{location}</div>
      </div>
    </div>
  )
}