import Img from "gatsby-image"
import { graphql, Link, StaticQuery } from "gatsby"
import { CallToAction } from "../../CallToAction"
import React from "react"

const styles = require("./styles.module.scss")

export const AnaBlock = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          cafeAnaJuli: file(relativePath: { eq: "anaSquared.png"}) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        // @ts-ignore
        return (

          <div className={styles.block}>
            <div>
              <Img alt={"Foto Ana Maria Cardona Ochoa Desde Malta"} className={styles.image} fluid={data.cafeAnaJuli.childImageSharp.fluid} loading={'eager'}/>
            </div>
            <div className={styles.text}>
              <h3>¿QUÉ ES DESDE MALTA?</h3>
              <div>
                <p>DESDE MALTA es un servicio de consultoría especializada en la planeación de viaje de estudios de
                  inglés a Malta.</p><p>
                Nuestro objetivo es brindar a cada estudiante una asesoría de 360 grados que involucre la
                realización de sus metas profesionales y personales, relacionadas con el aprendizaje de calidad y
                el sueño de conocer otras culturas ya inmerso en la isla.</p>
              </div>
              <div className={styles.cta}>
                <Link to={"/conocenos/"}><CallToAction className={styles.button}>Nosotros</CallToAction></Link>
                <Link to={"/paso-a-paso/"}><CallToAction className={styles.button}>Proceso</CallToAction></Link>
              </div>
            </div>
          </div>
        )
      }}/>)
}