import React from "react"
import Layout from "../components/layout"
import { graphql, Link, StaticQuery } from "gatsby"
import LazyLoad from 'react-lazyload';
import CustomHelmet from "../components/CustomHelmet"

// @ts-ignore
import styles from "./index.module.scss"
// @ts-ignore
import { CallToAction } from "../components/CallToAction"
import BackgroundImage from 'gatsby-background-image'
import { Carousel } from "../components/Carousel"
import { TestimonialCard } from "../components/TestimonialCard"
import { ArticleCarousel } from "../components/ArticleCarousel"
import { Contact } from "../components/Contact"
import { AnaBlock } from "../components/HomeComponents/AnaBlock"
import { Testimonials } from "../components/HomeComponents/Testimonials"

export default () => {

  return (
    <StaticQuery
      query={graphql`
        query {
          background: file(relativePath: { eq: "blueBackgroundC.png"}) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          background2: file(relativePath: { eq: "homeBackOpt.png"}) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },

        }
      `}
      render={data => {
        // @ts-ignore
        return (
          <>
            <Layout transparent={true}>
              <CustomHelmet
                title={"Desde Malta | Asesoría en la planeación de tu viaje de estudios"}
                description={"Basados en la experiencia con cientos de asesorados y nuestra presencia en Malta, te ayudaremos en la planeación de curso de Inglés en Malta, sin costo adicional"}
                link={"/"}
              />
              <BackgroundImage className={styles.image} alt={"Background image"} fluid={data.background2.childImageSharp.fluid} Tag="section">

              <main className={styles.home}>
                <h1>Tu curso ideal en Malta</h1>
                <div className={styles.subHeader}>
                  <h3>
                    Asesoría completamente profesional, personalizada y sin costo alguno
                  </h3>
                  <a className={styles.cta} href="https://mi.desdemalta.com/bienvenido/" target={"_blank"} rel="noreferrer noopener">
                    <CallToAction>Iniciar Asesoría</CallToAction>
                  </a>
                </div>
              </main>
              </BackgroundImage>
              <AnaBlock/>
              <div className={styles.support}>
                <h3> CON DESDE MALTA SIEMPRE TENDRÁS </h3>
                <div className={styles.items}>
                  <div className={styles.item}>
                    <img src={require("../images/conversation.svg")} className={styles.icon}
                         alt={"icon ASESORÍA con Desde Malta"}/>
                    <h4>
                      ASESORÍA PERSONALIZADA SIN COSTO ALGUNO
                    </h4>
                    <div className={styles.text}>
                      Conociendo tu perfil y objetivos personales, te ayudaremos a elegir la opción más acertada y
                      totalmente personalizada sin que tengas que pagar más por ello.
                    </div>
                  </div>
                  <div className={styles.item}>
                    <img src={require("../images/together.svg")} className={styles.icon}
                         alt={"icon ACOMPAÑAMIENTO con Desde Malta"}/>
                    <h4>
                      ACOMPAÑAMIENTO PERMANENTE
                    </h4>
                    <div className={styles.text}>
                      Al reservar a través de nosotros, contarás con nuestro acompañamiento y asesoría no solo antes de
                      tu viaje, sino durante tu estancia aquí en Malta.
                    </div>
                  </div>
                  <div className={styles.item}>
                    <img src={require("../images/qualified.svg")} className={styles.icon}
                         alt={"icon CALIDAD de Desde Malta"}/>
                    <h4>OBJETIVIDAD Y CALIDAD EN LA PLANEACIÓN</h4>
                    <div className={styles.text}>
                      Al estar desde la isla, conocemos muy bien las ofertas que hacen las escuelas de inglés. Por eso,
                      según tu perfil, ajustaremos para ti un plan de viaje con información transparente y de calidad
                    </div>
                  </div>
                </div>
                <div>
                  <h3> CUÉNTANOS DE TI Y DE TUS PLANES DE ESTUDIO </h3>
                  <div className={styles.formtext}>Dentro de 24 horas te enviaremos una oferta personalizada.</div>
                  <a href="https://mi.desdemalta.com/bienvenido/" rel="noreferrer noopener"><CallToAction>LLenar Formulario</CallToAction></a>
                </div>
                <BackgroundImage
                  Tag="section"
                  fluid={data.background.childImageSharp.fluid}
                  className={styles.background}
                >

                  <div className={styles.partners}>
                    <h3>ESCUELAS QUE CONFÍAN EN DESDE MALTA</h3>
                    <LazyLoad once={true}><Carousel/></LazyLoad>
                    <h3>TESTIMONIOS</h3>
                  </div>
                </BackgroundImage>
                <Testimonials>
                  <TestimonialCard
                    name={"Claudio"}
                    location={"Chile"}
                    text={"Toda la asistencia previa al viaje, donde me asesoraron que escuela yo podía escoger, los alojamientos, todos los comodities que tenía que revisar; así como todas las consultas que he tenido acá han sido gratuitos. Es un servicio profesional, disponible y muy eficiente"}
                  />
                  <TestimonialCard
                    name={"Janeth"}
                    location={"Colombia"}
                    text={"Es una asesoría muy sincera y honesta, donde te explican claramente las expectativas que puedes tener de acuerdo a tu presupuesto y eso te da claridad y seguridad a la hora de tomar la decisión de viajar a estudiar inglés"}
                  />
                  <TestimonialCard
                    name={"Senibaldo"}
                    location={"Venezuela"}
                    text={"Desde que he estado con Desde Malta, siempre que he necesitado información y me la han dado y nunca he tenido que pagar por ello ningún monto de dinero. Recomendados totalmente en cuanto a planificación, información pues definitivamente les irá muy bien!"}
                  />
                </Testimonials>
                <div className={styles.testimonialsCta}>
                  <div className={styles.formtext}>Conoce un poco más de las experiencias de nuestros asesorados</div>
                  <Link to={"/testimonios/"}><CallToAction>Ver más</CallToAction></Link>
                </div>
                <h3>ARTÍCULOS DE TU INTERÉS</h3>
                <LazyLoad><ArticleCarousel/></LazyLoad>
              </div>
              <Contact/>
            </Layout></>
        )
      }}
    />
  )
}
