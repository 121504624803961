import React from "react"
import { Link } from "gatsby"
import Typography from "@material-ui/core/Typography/Typography"
import HideMobile from "../Shared/HideMobile"
import css from "classnames"

const styles = require("./styles.module.scss")

export const Contact = () => {
  return (
    <div className={styles.contact}>
      <div className={styles.info}>
        <img src={require("../../images/LogoWhite.svg")} alt={"logo Desde Malta blanco"}/>
        <Link to={"/paso-a-paso/"} className={styles.item}>
          <Typography variant="h6" className={styles.white} noWrap>
            Paso a paso
          </Typography>
        </Link>
        <Link to={"/conocenos/"} className={styles.item}>
          <Typography variant="h6" className={styles.white} noWrap>
            Conócenos
          </Typography>
        </Link>
        <Link to={"/testimonios/"} className={styles.item}>
          <Typography variant="h6" className={styles.white} noWrap>
            Testimonios
          </Typography>
        </Link>
        <Link to={"/articulos/"} className={styles.item}>
          <Typography variant="h6" className={styles.white} noWrap>
            Artículos
          </Typography>
        </Link>
        <Link to={"/auto-estudio/"} className={styles.item}>
          <Typography variant="h6" className={styles.white} noWrap>
            Auto-estudio
          </Typography>
        </Link>
        <Link to={"/covid-19/"} className={styles.item}>
          <Typography variant="h6" className={styles.white} noWrap>
            COVID-19
          </Typography>
        </Link>
      </div>
      <div className={styles.info}>
        <HideMobile><Typography variant="h6" className={styles.white} noWrap>
          Contacto
        </Typography></HideMobile>
        <a href="https://wa.me/35699578513?text=Hola%2C+Quiero+iniciar+el+proceso+de+planeaci%C3%B3n+de+mi+viaje+de+estudios+a+Malta+con+Desde+Malta%21" className={styles.item}>
          <div className={styles.imgContainer}><img src={require("../../images/whatsappWhite.svg")} alt={"whatsapp"}/></div>
          <Typography variant="h6" className={styles.white} noWrap>+35699578513</Typography>
        </a>
        <a href={"https://www.instagram.com/desdemaltaoficial/"} className={styles.item}>
          <div className={styles.imgContainer}><img src={require("../../images/instagramWhite.svg")} alt={"instagram"}/></div>
            <Typography variant="h6" className={styles.white} noWrap>@desdemaltaoficial</Typography>
        </a>
        <a href={"https://www.facebook.com/DesdeMalta"} className={styles.item}>
          <div className={styles.imgContainer}><img src={require("../../images/facebookWhite.svg")} alt={"facebook"}/></div>
            <Typography variant="h6" className={styles.white} noWrap>@desdemalta</Typography>
        </a>
        <a href={"https://www.youtube.com/channel/UCeA-pbqZpgMPw2sFdKOvCtQ"} className={styles.item}>
          <div className={styles.imgContainer}><img src={require("../../images/youtubeWhite.svg")} alt={"youtube"}/></div>
            <Typography variant="h6" className={styles.white} noWrap>@desdemalta</Typography>
        </a>
        <a href="mailto:contacto@desdemalta.com" className={styles.item}>
          <div className={styles.imgContainer}><img src={require("../../images/mailWhite.svg")} alt={"correo electronico"}/></div>
          <Typography variant="h6" className={styles.white} noWrap>contacto@desdemalta.com</Typography>
        </a>

        <Typography variant="h6" className={css(styles.white, styles.text)} noWrap>Triq Paceville 3, St Julians, Malta</Typography>

      </div>
    </div>
  )
}