import React, { useState } from "react"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"

const styles = require("./styles.module.scss")

const handleDragStart = (e) => e.preventDefault()

const responsive = {
  0: { items: 0 },
  786: { items: 2 },
  1024: { items: 7 },
}

const items = [
  <img src={require("../../images/logoswhite/iels.svg")} onDragStart={handleDragStart} className={styles.logo} alt={"IELS"}/>,
  <img src={require("../../images/logoswhite/ESE.svg")} onDragStart={handleDragStart} className={styles.logo} alt={"ESE"}/>,
  <img src={require("../../images/logoswhite/ec.svg")} onDragStart={handleDragStart} className={styles.logo} alt={"EC Malta"}/>,
  <img src={require("../../images/logoswhite/ACE.svg")} onDragStart={handleDragStart} className={styles.logo} alt={"ACE"}/>,
  <img src={require("../../images/logoswhite/am.svg")} onDragStart={handleDragStart} className={styles.logo} alt={"AM Language"}/>,
  <img src={require("../../images/logoswhite/BELS.svg")} onDragStart={handleDragStart} className={styles.logo} alt={"BELS"}/>,
  <img src={require("../../images/logoswhite/ELA.svg")} onDragStart={handleDragStart} className={styles.logo} alt={"ELA"}/>,
  <img src={require("../../images/logoswhite/easy.svg")} onDragStart={handleDragStart} className={styles.logo} alt={"Easy"}/>,
  <img src={require("../../images/logoswhite/gvmalta.svg")} onDragStart={handleDragStart} className={styles.logo} alt={"GV Malta"}/>,
  <img src={require("../../images/logoswhite/SC.svg")} onDragStart={handleDragStart} className={styles.logo} alt={"EC"}/>,
  <img src={require("../../images/logoswhite/inlingua.svg")} onDragStart={handleDragStart} className={styles.logo} alt={"Inlingua"}/>,
]

export const Carousel = () => {

  const [activeIndex, setActiveIndex] = useState(0)

  const slidePrev = () => setActiveIndex(activeIndex - 1)
  const slideNext = () => setActiveIndex(activeIndex + 1)
  const onSlideChanged = ({ item }) => setActiveIndex(item)

  return (
    <div className={styles.carousel}>
      <div className={styles.ctrl} onClick={slidePrev}><img src={require("../../images/arrowLeft.svg")} alt={"anterior"}/></div>
      <div className={styles.container}>
      <AliceCarousel
        mouseTracking
        items={items}
        responsive={responsive}
        activeIndex={activeIndex}
        disableDotsControls
        disableButtonsControls
        infinite
        autoPlay
        onSlideChanged={onSlideChanged}
      />
      </div>
      <div className={styles.ctrl} onClick={slideNext}><img src={require("../../images/arrowRight.svg")} alt={"siguiente"}/></div>
    </div>
  )
}