import React from "react"
// @ts-ignore
import styles from "./styles.module.scss"

export const Testimonials = (props) => {
  return (
    <div className={styles.testimonials}>
      {props.children}
    </div>
  )
}