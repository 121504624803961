import { graphql, StaticQuery } from "gatsby"
import ArticlePreview from "../ArticlePreview"
import { ImageType } from "../PreviewItem"
import React, { useState } from "react"
import AliceCarousel from "react-alice-carousel"
import { CallToAction } from "../CallToAction"
import css from "classnames"
import { VideoContainer } from "../Videos/VideoContainer"
import { videos } from "../../data/videos"

const styles = require("./styles.module.scss")

const responsive = {
  0: { items: 0 },
  786: { items: 2 },
  1024: { items: 4 },
}

enum ContentType {
  Video = "Video",
  Post = "Post"
}

export const ArticleCarousel = () => {

  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressPost(sort: { fields: [date] }) {
            edges {
              node {
                title
                excerpt
                slug
                categories
                featured_media {
                  alt_text
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 400, maxHeight: 300, quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              acf {
                type
                show
              }
            }
          }
        }
      }`}
      render={data => {
        const [activeIndex, setActiveIndex] = useState(0)
        const [contentType, setContentType] = useState(ContentType.Post)
        const slidePrev = () => setActiveIndex(activeIndex - 1)
        const slideNext = () => setActiveIndex(activeIndex + 1)
        const onSlideChanged = ({ item }) => setActiveIndex(item)

        const postPreviewItems = data.allWordpressPost.edges.filter(({ node }) => Boolean(node.acf && node.acf.show)).slice(0, 6).map(({ node }) => {
          return <ArticlePreview
            link={"/" + node.slug + "/"}
            imageUrl={node.featured_media ? node.featured_media.localFile.childImageSharp.fluid : ""}
            title={node.title}
            key={node.slug}
            imageType={ImageType.round}
            imageAlt={node.featured_media ? node.featured_media.alt_text : ""}
            categoryTag={node.acf.type}
          />
        })

        const videoPreviewItems = videos.map(video => {
          return (
            <div className={styles.video} key={video.videoId}>
              <VideoContainer title={video.title} id={video.videoId} description={video.title}/>
            </div>)
        })

        return (
          <div className={styles.articleCarousel}>
            <div className={styles.container}>
              <AliceCarousel
                mouseTracking
                items={contentType === ContentType.Post ? postPreviewItems : videoPreviewItems}
                responsive={responsive}
                activeIndex={activeIndex}
                disableDotsControls
                disableButtonsControls
                infinite
                onSlideChanged={onSlideChanged}
              />
            </div>
            <div className={styles.ctrlContainer}>
              <img className={styles.ctrl} src={require("../../images/arrowLeftWhite.svg")} onClick={slidePrev}
                   alt={"anterior"}/>
              <CallToAction onClick={() => setContentType(ContentType.Post)}
                            className={css(styles.cta, { [styles.inactive]: contentType !== ContentType.Post })}>Post</CallToAction>
              <CallToAction onClick={() => setContentType(ContentType.Video)}
                            className={css(styles.cta, { [styles.inactive]: contentType !== ContentType.Video })}>Videos</CallToAction>
              <img className={styles.ctrl} src={require("../../images/arrowRightWhite.svg")} onClick={slideNext}
                   alt={"siguiente"}/>
            </div>
          </div>
        )
      }}
    />)
}

