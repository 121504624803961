import React from "react"

// @ts-ignore
import styles from "./styles.module.scss"

interface Props {
  id: string;
  description: string;
  title: string;
  children?: any;
}


export const VideoContainer = ({ id, title, description, children }: Props) => {
  return (
    <div className={styles.videoFrame}>
      {/*<div className={styles.title}>{title}</div>*/}
      <div className={styles.videoContainer}>
        {children || <iframe src={`https://www.youtube.com/embed/${id}?rel=0`} title={title || "Video de Desde Malta youtube"} loading={"lazy"}  frameBorder="0" allowFullScreen/>}
      </div>
      {description ? <div className={styles.description}>{description}</div> : <></>}
    </div>
  )
}